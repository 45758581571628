<template>
  <main>
    <md-card
      class="profile-card"
      :style="$publicProfileBg"
    >
      <md-card-content class="profile-card-content">
        <div class="profile-card-grid">
          <img
            class="profile-card-image"
            alt="Student Image"
            :src="publicProfile.profile_image"
          >

          <p class="green-text">
            {{ publicProfile.template.name }}
          </p>

          <h3 class="profile-card-student-name light-text">
            <b>
              {{ publicProfile.full_name }}
            </b>
          </h3>

          <div class="profile-card-student-country">
            <p class="green-text">
              <b>
                {{ publicProfile.nationality }}
              </b>
            </p>
          </div>

          <div class="profile-card-student-infos">
            <div class="profile-card-student-infos-columns">
              <div>
                <p class="green-text">
                  {{ publicProfile.template.student_number }}
                </p>
                <h4 class="light-text">
                  <b>
                    {{ publicProfile.student_number }}
                  </b>
                </h4>
              </div>

              <div>
                <p class="green-text">
                  {{ publicProfile.template.date_of_birth }}
                </p>
                <h4 class="light-text">
                  <b>
                    {{ publicProfile.date_of_birth }}
                  </b>
                </h4>
              </div>
            </div>

            <div class="profile-card-student-infos-columns">
              <div>
                <p class="green-text">
                  {{ publicProfile.template.campus }}
                </p>

                <h4 class="light-text">
                  <b>
                    {{ publicProfile.campus }}
                  </b>
                </h4>
              </div>

              <div>
                <p class="green-text">
                  {{ publicProfile.template.expire_date }}
                </p>

                <h4 class="light-text">
                  <b>
                    {{ publicProfile.finish_date }}
                  </b>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <section class="profile-footer">
      <div
        class="profile-footer-grid"
        v-html="publicProfile.template.terms"
      />

      <img
        :src="publicProfile.template.logo"
        alt="College Logo"
        class="profile-footer-logo"
      >
    </section>
  </main>
</template>

<script>
export default {
  beforeRouteEnter: (to, _, next) => {
    if (!to.query.token) next('/login');
    next();
  },
  data: () => ({
    publicProfile: {},
  }),
  created() {
    const { token } = this.$route.query;
    this.axios
      .get(
        `${process.env.VUE_APP_SERVER}students/public_profile?token=${token}`,
      )
      .then(({ data: response }) => {
        const { data } = response;
        this.publicProfile = data;
      })
      .catch((err) => {
        const { message } = err.response.data;
        this.fireError(message);
        this.$router.push('/login');
      });
  },
};
</script>

<style scoped lang="scss">
$container-width: 230px;
$gutter: 2em;
$blue: #0233f7;
$dark-blue: #00124c;
$green: #30d9af;
$light: #f4f5f8;

p,
h1,
h3,
h4 {
  margin: 0;
  line-height: initial;
}

@mixin get-column($gap: ".75em") {
  display: flex;
  flex-direction: column;
  gap: #{$gap};
}

.green-text {
  color: $green;
}

.light-text {
  color: $light;
}

.profile-card {
  margin: 0 0 2em 0;
  border-radius: 0 0 2em 2em;

  &-image {
    height: 12em;
    max-width: 12em;
    border-radius: 2em;
  }

  &-content {
    padding: 0;
  }

  &-grid {
    @include get-column;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 2em 0;
  }

  &-student-name {
    text-align: center;
    max-width: $container-width;
  }

  &-student-country {
    padding: 0 2.75em;
    border-radius: 1em;
    background-color: $dark-blue;
    margin-bottom: 1em;
  }

  &-student-infos {
    display: flex;
    max-width: $container-width;
    gap: $gutter;
  }

  &-student-infos-columns {
    @include get-column;
  }
}

.profile-footer {
  @include get-column("2em");
  align-items: center;
  padding: 0 1em;
  margin: 2em auto;
  max-width: #{$container-width * 2.5};
  color: $dark-blue;

  &-grid {
    @include get-column;
  }

  &-logo {
    height: 6em;
    max-width: $container-width;
  }
}
</style>
